@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Rouge+Script&family=Pinyon+Script&display=swap');

*::-webkit-scrollbar {
    display: block;
    width: 5px;
    height: 8px;
    background-color: rgba(251, 113, 133, 0.5);
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(251, 113, 133);
}

*:focus {
    outline: none;
}

input {
    border-radius: 0;
}

body {
    margin: 0;
    font-family: 'Noto Sans TC', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

button {
    user-select: none;
}

/* override tailwind forms select carat */
select {
    background-image: none;
}

/* .bg-texture-\[asfalt-light\] {
    background: url("./assets/asfalt-light.png");
    background-color: #695436;
}

.bg-texture-\[crossword\] {
    background: url("./assets/crossword.png");
    background-color: #9c4d4b;
} */

.pause-symbol {
    /* box-sizing: border-box; */
    /* position: relative; */
    display: block;
    /* transform: scale(var(--ggs,1)); */
    min-width: 8px;
    min-height: 11px;
    width: 100%;
    height: 100%;
    border-left: 3px solid;
    border-right: 3px solid
}

.cursor-none button,
.cursor-none .cursor-pointer {
    cursor: none;
}
